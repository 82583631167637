// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
if (document.querySelectorAll(".swiper-container .swiper-slide").length > 1) {
    new Swiper (".swiper-container", {
        autoplay: 5000,
        speed: 150,
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        nextButton: ".swiper-button.-next",
        prevButton: ".swiper-button.-prev",
    });
}
